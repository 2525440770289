.play-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: rgba(0,0,0,.8);
    padding: 4px 2px;
    cursor: pointer;
    z-index: 99;
    border-radius: 50%;
    color: #fff;

    &:hover {
        background-color: #000;
    }
}