.sport-selector-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    &.mobile {
        .league-item {
            width: calc(42%);
        }

        .teams {
            min-width: 100%;
            max-width: 100%;
        }
    }

    .leagues,
    .teams {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        box-sizing: border-box;

        .select {
            width: 100%;
        }
    }

    .teams {
        min-width: 500px;
    }

    .league-item {
        width: calc(50% - 120px);
        margin-bottom: 18px;
        padding: 12px;

        span {
            font-size: 1em;
        }

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }

    .team-item {
        width: calc(50% - 34px);
        padding: 8px;
        margin-bottom: 8px;
        
        img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 8px;
        }

        span {
            font-size: .9em;
        }
    }

    .league-item,
    .team-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s;
    
        @media (hover:hover) {
            &:hover {
                transform: scale(1.06);
                color: #007bff;
            }
        }
    
        &.selected {
            background-color: #007bff;
            color: #fff;
            font-weight: 700;
            transform: scale(1.06);
    
            @media (hover:hover) {
                &:hover {
                    background-color: #007bff;
                }
            }
        }
    }

    .error-message {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff333d;
        font-size: 12px;
        margin-top: 4px;
    }
}