.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    background-color: #f6f9fc;
    padding-top: 78px;

    &.mobile {
        flex-direction: column;
    }
}

.hero-gradient {
    --gradientAngle: var(--angleStrong);
    --gradientHeight: calc(100% + var(--sectionPaddingTop) + var(--transformOriginX)*var(--sectionAngleSin));
    --transformOriginX: -60px;
    --sectionAngleSin: var(--angleStrongSin);
    position: absolute;
    bottom: 0;
    top: auto;
    left: 0;
    width: var(--windowWidth);
    height: var(--gradientHeight);
    transform-origin: var(--transformOriginX) 100%;
    transform: skewY(var(--gradientAngle));
    overflow: hidden;
    border: none;

    @media (min-width: 600px) {
        --transformOriginX: calc(var(--gutterWidth)*0.8);
    }

    &:after {
        content: "";
        z-index: -1;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        min-width: 1000px;
        width: 100%;
        height: 100%;
        max-height: 600px;
        background: radial-gradient(var(--gradientColorThree) 23%, var(--gradientColorThreeTransparent) 67% 100%) 385px -24px,
                radial-gradient(var(--gradientColorOne) 0, var(--gradientColorOneTransparent) 60% 100%) -940px 290px,
                radial-gradient(var(--gradientColorTwo) 10%, var(--gradientColorTwoTransparent) 60% 100%) -120px 250px,
                radial-gradient(var(--gradientColorOne) 40%, var(--gradientColorOneTransparent) 57% 100%) 495px -44px,
                radial-gradient(var(--gradientColorZero) 30%, var(--gradientColorZeroTransparent) 67% 100%) 122px -120px,
                radial-gradient(var(--gradientColorZero) 10%, var(--gradientColorZeroTransparent) 60% 100%) -420px 120px,
                radial-gradient(var(--gradientColorTwo) 15%, var(--gradientColorTwoTransparent) 50% 100%) -620px 0,
                radial-gradient(var(--gradientColorTwo) 25%, var(--gradientColorTwoTransparent) 50% 100%) 520px -250px,
                var(--gradientColorZero);
        background-repeat: repeat-y;
    }

    &.mobile {
        height: calc(100% + calc(72 *1px + (116 - 72)*(calc(100vw - 0px) /737 - 0.50882px)) + -60px * 0.212);
        
        &:after {
            transition: transform 1s 1s;
            transform: translateX(-50%) scaleY(.995);
            max-height: 436px;
        }
    }
}

.hero-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(var(--layoutWidth) + 40px);
    border: dashed 1px rgba(66, 71, 112, 0.09);
    border-bottom: none;
    border-top: none;
    padding: 0 20px;

    &.mobile {
        flex-direction: column;
        // max-width: 100vw;
    }
}