.privacy {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;
    padding-top: 78px;

    .privacy-wrapper {
        display: flex;
        flex-direction: column;
        width: calc(var(--layoutWidth) + 40px);
        border: dashed 1px rgba(66, 71, 112, 0.09);
        border-bottom: none;
        border-top: none;
        padding: 0 20px 80px;
    }
}