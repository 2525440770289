.image-section {
    flex: 1;
    position: relative;

    .dashboard-graphic {
        --graphic-width: 48vw;
        width: var(--graphic-width);
        max-width: 729px;
        height: calc(var(--graphic-width) * 9 / 16);
        max-height: calc(729px * 9 / 16);
        padding: 8px;
        border-radius: 8px;
        background: linear-gradient(hsla(0, 0%, 100%, .4), hsla(0, 0%, 100%, .3) 25%, rgba(246, 249, 252, .3) 50%, #f6f9fc 60%);
        box-shadow: inset 0 1px 1px 0 hsla(0, 0%, 100%, .1), 0 50px 100px -20px rgba(50, 50, 93, .25), 0 30px 60px -30px rgba(0, 0, 0, .3);
        user-select: none;
        position: absolute;
        top: 120px;
        left: 0;
        z-index: 1;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
        }
        
        &.mobile {
            position: relative;
            width: 100%;
            height: auto;
            top: auto;
            left: auto;
        }
        
    }

    .phone-graphic {
        --phoneBorderRadius: 36px;
        --phoneScreenBorderRadius: 29px;
        --phoneWidth: 264px;
        --phoneHeight: 533px;
        --phoneGraphicShadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3), inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);
        width: var(--phoneWidth);
        height: var(--phoneHeight);
        padding: 8px;
        border-radius: var(--phoneBorderRadius);
        background: #f6f9fc;
        box-shadow: var(--phoneGraphicShadow);
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        font-size: 16px;
        position: relative;
        margin-top: 120px;
        left: 100px;
        z-index: 1;
        visibility: hidden;

        .screen {
            position: relative;
            height: 100%;
            border-radius: var(--phoneScreenBorderRadius);
            // -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
            background: #fff;
        }
    }

    img {
        max-width: 100%;
        height: auto;
        
    }
}