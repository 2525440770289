.step-navigation {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    width: 100%;
    position: relative;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px 8px 0 0;

    &.mobile {
        justify-content: space-evenly;
        max-width: 100vw;
        padding-left: 7px;
        z-index: 2;

        .step {
            .step-circle {
                width: 34px;
                height: 34px;
                border: 4px solid #fff;
                font-size: .6em;
            }
        }

        .step-line {
            visibility: hidden;
        }
    }

    .step-line {
        width: 80%;
        height: 1px;
        background-color: #ccc;
        position: absolute;
        z-index: 1;
        top: 50%;
    }

    .step-progress {
        height: 100%;
        background-color: #9801cc;
        width: 0;
        transition: width 0.3s ease-in-out;
    }

    .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .step-circle {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #ccc;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .8em;
            color: #333;
            transition: background-color 0.3s ease-in-out;
            z-index: 2;
            border: 8px solid #fff;

            &:not(:first-child) {
                border: 8px solid #fff;
            }
        }

        &.active {
            .step-circle {
                background-color: #9801cc;
                color: #fff;
            }

            .step-name {
                font-weight: 700;
            }
        }

        .step-name {
            font-size: 0.8em;
            color: #333;
            position: absolute;
            top: 12px;
            left: 40px;
            background-color: #fff;
            z-index: 2;
            padding: 0 8px 0 0;
        }
    }
}