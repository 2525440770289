.block-options {
    display: flex;
    width: 100%;
    padding: 20px 20px 0;
    flex-direction: column;
    box-sizing: border-box;

    .block-options-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .option {
            width: calc(50% - 42px);
            margin-bottom: 20px;
            display: flex;
            height: 100px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 16px;
            background-color: #fff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: transform 0.2s;

            &.mobile {
                width: calc(40%) !important;

                .option-label {
                    font-size: .7em;
                }
            }
            
            @media (hover:hover) {
                &:hover {
                    transform: scale(1.06);
                    color: #007bff;
                }
            }

            &.selected {
                background-color: #007bff;
                color: #fff;
                font-weight: 700;
                transform: scale(1.06);

                @media (hover:hover) {
                    &:hover {
                        background-color: #007bff;
                    }
                }

                .option-label {
                    .context {
                        color: darken(#fff, 5%) !important;
                        font-weight: 400;
                    }
                }
            }

            .option-icon {
                font-size: 1.2em;
                margin-bottom: 12px;
            }

            .option-label {
                font-size: .9em;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                .context {
                    font-size: .7em;
                    color: lighten(#425466, 20%);
                    margin-top: 2px;
                    text-align: center;
                }
            }
        }
    }

    .error-message {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff333d;
        font-size: 12px;
        margin-top: 4px;
    }
}