.title-section {
    flex: 1;
    padding: 40px 80px 40px 0;
    z-index: 2;

    p {
        font-size: 1em;
        line-height: 28px;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    &.mobile {
        padding: 40px 0;
        
        p {
            font-size: .9em;
            line-height: 26px;
        }
    }

    .title-cta {
        display: flex;
        align-items: center;
        justify-content: left;

        .chevron-icon {
            margin-left: 5px;
        }

        .play-icon {
            margin-right: 5px;
        }

        .btn:first-child {
            margin-left: 0 !important;
        }
    }
}