.rotating-container {
    margin: 80px 0 20px 0;

    &.mobile {
        margin: 40px 0 20px 0;
    }
    
    .no-rotate {
        font-size: 6em;
        font-weight: 500;
        letter-spacing: -.3rem;
        color: #000;
        mix-blend-mode: color-burn;
    }

    .rotating-word {
        color: #000;
        background-color: white;
        font-size: 6em;
        font-weight: 500;
        letter-spacing: -.3rem;
        padding: 0 15px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 8px;
    }

    &.mobile {

        .no-rotate {
            font-size: 3.4em;
            letter-spacing: -.2rem;
            font-weight: 700;
        }

        .rotating-word {
            font-size: 3.4em;
            letter-spacing: -.2rem;
            font-weight: 700;
        }
    }
}