
.marketplaces {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    padding: 0;
    background-color: #f6f9fc;

    &.mobile {
        .marketplaces-content {
            flex-direction: column;
            .marketplaces-announce-wrapper {
                padding: 0;
                justify-content: center;
                
                svg {
                    display: none;
                }

                .marketplaces-announce {
                    padding: 0 0 20px;

                    span {
                        text-align: center;
                    }

                    .large {
                        font-size: 1.2em;
                        letter-spacing: normal;
                    }
                }
            }
        }
    }

    .marketplaces-content {
        display: flex;
        width: calc(var(--layoutWidth) + 40px);
        border: dashed 1px rgba(66, 71, 112, 0.09);
        border-bottom: none;
        border-top: none;
        padding: 40px 0 60px;
        align-items: center;

        .marketplaces-announce-wrapper {
            display: flex;
            flex: .7;
            padding: 0 0 0 20px;
            align-items: center;

            svg {
                font-size: 2em;
            }

            .marketplaces-announce {
                display: flex;
                flex-direction: column;
                padding: 0 20px 0 0;
        
                .large {
                    font-size: 2em;
                    letter-spacing: -0.1rem;
                    color: #000;
                    font-weight: 700;
                    margin-bottom: 10px;
                }
            }
        }

        .marketplaces-partners {
            flex: 2;
            box-sizing: border-box;
            overflow: hidden;
        }
    }
}
