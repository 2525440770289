.confetti-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    overflow: hidden;
    opacity: 1;
    transition: opacity 0.5s ease;

    &.hidden {
        opacity: 0;
    }
}