.tooltip-anchor {
    font-size: .6em;
    font-weight: 400;
    text-decoration: none;

    @media (hover:hover) {
        &:hover {
            color: #7a0198;
        }
    }
}

.tooltip-content {
    max-width: 250px;
    text-align: center;
    z-index: 9;

    span {
        font-size: .8em;
    }
    
    a {
        color: #fff;
        text-decoration: underline;
    }
}