.loading-container {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 300px;

    .loading-spinner {
        position: absolute;
        transform:translateX(50%);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #007bff;

        .svg-inline--fa {
            font-size: 2rem;
        }
    }
}
