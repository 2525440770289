.how {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    padding: 0;
    flex-direction: column;

    &.solver {
        background-color: #0a2540;
    }

    .headline {
        font-size: 1.4em;
        font-weight: 700;
        color: #9801cc;
        padding-top: 80px;
        padding-bottom: 40px;
        width: calc(var(--layoutWidth) + 40px);
        border: dashed 1px rgba(66, 71, 112, 0.09);
        border-bottom: none;
        border-top: none;
        text-align: center;
    }

    .how-steps {
        display: flex;
        align-items: stretch;
        padding-top: 40px;
        padding-bottom: 80px;
        width: calc(var(--layoutWidth) + 40px);
        border: dashed 1px rgba(66, 71, 112, 0.09);
        border-bottom: none;
        border-top: none;

        &.mobile {
            flex-direction: column;
            padding-bottom: 40px;

            .how-step {
                padding-bottom: 20px;

                &.step-arrow {
                    display: none;
                }
            }
        }

        .how-step {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            padding: 0 20px;

            .step-number {
                border-radius: 50%;
                font-size: 1.2em;
                font-weight: 700;
                color: #fff;
                background-color: #000;
                text-align: center;
                padding: 3px 9px;
            }

            .step-title {
                font-size: 2em;
                font-weight: 700;
                color: #000;
                margin: 12px 0;
                letter-spacing: -0.1rem;
            }

            .step-description {
                text-align: center;
                line-height: 28px;
            }
            
            &.step-arrow {
                flex: 0.25;
                justify-content: center;
                align-items: center;
                padding: 0;
                color: #000;
            }
        }
    }

    .how-problem {
        display: flex;
        justify-content: center;
        width: calc(var(--layoutWidth) + 40px);

        &.mobile {
            .how-problem-content {
                font-size: 1em;
                letter-spacing: normal;
            }
        }
        

        .how-problem-content {
            display: flex;
            flex: 1;
            align-items: center;
            padding: 20px 0;
            box-sizing: border-box;
            justify-content: center;

            font-size: 3em;
            font-weight: 700;
            color: #fff;
            letter-spacing: -0.2rem;

            width: calc(var(--layoutWidth) + 40px);
            border: dashed 1px rgba(66, 71, 112, 0.09);
            border-bottom: none;
            border-top: none;

            span {
                margin: 0 9px;
                background-color: #fff;
                color: #0a2540;
                border-radius: 8px;
                padding: 0 8px;
            }
        }
    }
    

    .how-content {
        display: flex;
        justify-content: center;
        width: calc(var(--layoutWidth) + 40px);
        padding: 0 0 80px 0;
        align-items: stretch;
        border: dashed 1px rgba(66, 71, 112, 0.09);
        border-bottom: none;
        border-top: none;
        margin-bottom: -20vh;

        &.mobile {
            flex-direction: column;

            .how-item:not(.right) {
                padding-bottom: 40px;
            }

            .how-item {
                padding-top: 40px;

                .how-solution-ready {
                    margin-top: 20px;
                }
            }
        }
    }

    .how-item {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 80px 40px 0;
        box-sizing: border-box;
        position: relative;
        padding-bottom: 140px;

        &:last-child {
            background-color: #90e0ff;
            clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
        }
    }

    .left {
        .how-solution-ready {
            color: #000;
            font-weight: 700;
            margin: 80px 0 20px;
        }
    
        .how-solution-ready-sub {
            margin-bottom: 20px;
        }

        .how-cta {
            margin-left: 0;
            flex-grow: 0;
        }
    }

    .left,
    .right {
        .how-solution {
            color: #9801cc;
            mix-blend-mode: color-burn;
            font-size: 1.4em;
            font-weight: 700;
            margin-bottom: 20px;
        }

        .how-problem-container {
            border-radius: 8px;
            display: flex;
            flex-direction: column;
    
            .how-problem-item {
                position: relative;
                padding-left: 20px;
                margin-bottom: 12px;
                margin-left: 4px;

                &:not(.problem) {
                    margin-bottom: 18px;
                    line-height: 1.5;
                }
    
                &:before {
                    content: "✓";
                    position: absolute;
                    left: 0;
                    color: #9801cc;
                    mix-blend-mode: color-burn;
                }

                &.problem {
                    &::before {
                        content: "x";
                        left: 2px;
                    }
                }
            }
        }
    }
}