.navigation {
    flex: 2;
    display: flex;
    justify-content: left;
    gap: 12px;

    &.mobile {
        flex: 1;
        flex-direction: column;

        .nav-element,
        .nav-button {
            color: #3a3a3a !important;

            &.active {
                background-color: #000;
                color: #fff !important;
            }
        }
    }

    .nav-element,
    .nav-button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 600;
        transition: all 200ms ease;
        text-decoration: none;
        color: white;

        @media (hover:hover) {
            &:hover {
                background-color: white;
                color: #3a3a3a;
            }
        }

        &.active {
            background-color: white;
            color: #3a3a3a;
        }
    }
}