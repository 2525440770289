.sticky-header {
    position: fixed;
    top: 0;
    background-color: transparent;
    z-index: 1000;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s;
    padding: 20px 0;
    width: 100%;

    &.scrolled {
        background-color: rgba(0, 0, 0, 0.8);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    &.non-home {
        background-color: rgba(0, 0, 0, 0.8);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
}

.container {
    position: relative;
    max-width: calc(var(--columnPaddingNormal)*2 + var(--layoutWidth));
    margin: 0 auto;
    padding: 0 var(--columnPaddingNormal);
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
        width: 100px;
        border: none;
        outline: none;
        pointer-events: none;
    }
}

.menu-icon {
    color: #fff;
    font-size: 1.5em;
    margin-top: -7px;
}

.nav-buttons {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: right;
}