.grecaptcha-badge {
    visibility: hidden;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;
    padding-top: 78px;
}

.modal-body {
    display: flex;
    flex-direction: column;
    width: calc(var(--layoutWidth) + 40px);
    border: dashed 1px rgba(66, 71, 112, 0.09);
    border-bottom: none;
    border-top: none;
    padding: 0 20px 80px;

    &.mobile {
        
        h1 {
            margin: 25px 10px 10px;
            font-size: 1em;
        }
    }

    h1 {
        margin: 60px 0 40px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -.02rem;
    }

    .existing-account {
        margin: 40px 0;
        display: flex;
        justify-content: center;

        .link {
            margin: 0 0 0 5px;
            cursor: pointer;
            color: #9801cc;
        }
    }

    .step-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f6f6f6;
        border-radius: 8px;
        border: none;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        padding: 0;

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .form-container {
                display: flex;
                flex-direction: column;
                padding: 40px;
                box-sizing: border-box;
                max-width: 580px;
                min-height: 300px;

                &.mobile {
                    max-width: 100vw;
                    width: 100vw;
                    padding-bottom: 40px;

                    .form-field {
                        &.completed {
                            span {
                                width: 100%;
                            }
                        }
                    }

                    .form-group {
                        display: flex;
                        flex-direction: column;

                        .form-field {
                            margin-right: 0 !important;

                            &.select {
                                [class$="-control"] {
                                    min-width: 100% !important;
                                }
                        
                                &.mid {
                                    [class$="-control"] {
                                        max-width: 100%!important;
                                    }
                                }
                            }
                        }
                    }
                }

                .volume-option {
                    height: 60px;
                    width: calc(50% - 122px);
                    margin-bottom: 20px;

                    &:last-child {
                        width: calc(60% - 8px);
                    }
                }

                .referral-option {
                    height: 60px;
                    width: calc(28% - 12px);
                    margin-bottom: 20px;
                    padding: 5px;

                    .option-label {
                        font-size: .7em;
                        text-align: center;
                    }
                }

                .section-divider-custom {
                    margin-top: 0;
                }

                label:not(.checkbox-label) {
                    font-size: .83em;
                    color: rgb(117, 117, 117);
                }
            
                input:not(.checkbox) {
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    outline: none;

                    &:focus {
                        border-color: #9801cc;
                    }
                }

                &:not(.select) {
                    input:not(.checkbox) {
                        min-height: 20px;
                        margin-bottom: 12px;
                        padding: 8px;
                    }
                }

                .form-field {
                    position: relative;
                    display: flex;
                    flex-direction: column;

                    &.completed {
                        align-items: center;

                        h2 {
                            text-align: center;
                            letter-spacing: -0.1rem;
                        }

                        span {
                            text-align: center;
                            width: 55%;
                            margin-bottom: 26px;
                        }

                        .next-steps {
                            font-size: .9em;

                            &.error {
                                color: red;
                                margin-bottom: 26px;
                            }
                            
                        }

                        .next-steps-spam {
                            color: lighten(#425466, 20%);
                            font-size: .7em;
                            padding: 10px;
                            background-color: #fff;
                            margin-top: 26px;
                            border: solid 1px #ccc;
                            border-radius: 4px;

                            .cs-link {
                                margin-left: 2px;
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }

                        .sign-up-complete {
                            background-color: #000;
                            color: #fff;
                        }
                    }

                    .checkbox-label {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        font-size: .9em;
                        background-color: #fff;
                        padding: 10px;
                        border: 1px solid #ccc;
                        border-radius: 4px;

                        a {
                            margin-left: 4px;
                        }
                
                        .checkbox-custom {
                            margin-right: 4px;
                            display: inline-block;
                            position: relative;
                            width: 18px;
                            height: 18px;
                            font-size: 1.2em;
                
                            .checked,
                            .unchecked {
                                position: absolute;
                                top: 1px;
                                left: 0;
                                opacity: 0;
                                visibility: hidden;
                            }
                        }
                
                        input[type="checkbox"] {
                            position: absolute;
                            opacity: 0;
                            visibility: hidden;
                
                            &:checked+.checkbox-custom .checked {
                                opacity: 1;
                                visibility: visible;
                            }
                
                            &:not(:checked)+.checkbox-custom .unchecked {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }

                    label:not(.checkbox-label) {
                        position: absolute;
                        top: 0;
                        left: 5px;
                        transition: font-size 0.1s ease, top 0.1s ease, opacity 0.1s ease;
                        pointer-events: none;
                        opacity: 0;
                        visibility: hidden;
                        border-radius: 4px;
                    }
                
                    input:not(:placeholder-shown)+label,
                    label.filled,
                    label.focused {
                        font-size: 0.5em;
                        top: -7px;
                        padding: 1px 4px;
                        background-color: #fff;
                        transition: font-size 0.1s ease, top 0.1s ease, opacity 0.1s ease;
                        pointer-events: none;
                        opacity: 1;
                        visibility: visible;
                    }

                    label.focused {
                        color: #9801cc;
                    }

                    input:not(:placeholder-shown):focus+label {
                        color: #9801cc;
                    }

                    .select {
                        font-size: .9em;
                    }

                    .select:not(.error) {
                        margin-bottom: 12px;
                    }

                    .PhoneInput {
                        &.PhoneInput--reallyFocused {
                            .PhoneInputCountry {
                                border-color: #9801cc;
                            }
                        }

                        .PhoneInputCountry {
                            margin-right: 0 !important;
                            padding-left: 0.5em !important;
                            padding-right: 0.5em !important;
                            background: white;
                            border: 1px solid #ccc;
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                            border-right: none;
                            margin-bottom: 12px;
                            outline: none;

                            select {
                                outline: none !important;
                            }
                        }

                        input {
                            border-left: none;
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }

                    &.select {
                        [class$="-control"] {
                            min-width: 148px;
                            min-height: 33.5px;

                            [class$="-Input"] {
                                margin-top: 0;
                                margin-bottom: 0;
                                padding: 0;
                            }

                            [class$="-indicatorContainer"] {
                                padding: 2px 8px;
                            }
                        }

                        &.mid {
                            [class$="-control"] {
                                max-width: 148px;
                            }
                        }

                        &.error {
                            [class$="-control"] {
                                // This target's the necessary wrapper for react-select (e.g. "css-13cymwt-control")
                                border: 1px solid #d79f9f;
                                background-color: #FAF0F0;
                            }
                            
                        }
                    }

                    .error:not(.select) {
                        font-size: .7em;
                        color: #ff333d;
                        margin: 3px 0 12px 1px;
                    }

                    &.error:not(.select) {
                        input,
                        .PhoneInputCountry {
                            margin-bottom: 0;
                            border: 1px solid #d79f9f;
                            background-color: #FAF0F0;

                            &.PhoneInputInput {
                                border-left: none;
                            }
                        }

                        .PhoneInputCountry {
                            border-right: none;
                        }
                    }
                }

                .form-group {
                    display: flex;

                    .form-field:not(.select) {
                        flex: 1;
                        margin-right: 8px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            .button-group {
                display: flex;
                width: 100%;

                button {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 20px 0;
                    background-color: #9801cc;
                    color: white;
                    border: none;
                    border-radius: 8px;
                    margin: 0;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    cursor: pointer;
                    transition: background-color 200ms ease-in-out;
                    font-size: 1em;
                    font-weight: 700;

                    @media (hover:hover) {
                        &:hover:not(:first-child) {
                            background-color: #7a0198;
                        }

                        &:hover:first-child {
                            background-color: rgb(175, 175, 175);
                        }
                    }
            
                    &:hover {
                        background-color: #7a0198;
                    }
            
                    &:not(:first-child) {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 8px;
                    }

                    &:first-child {
                        background-color: #ccc;
                        color: #000;
                        font-weight: 400;
                    }

                    .chevron-icon {
                        margin-left: 8px;
                    }

                    .chevron-icon-left {
                        margin-right: 8px;
                    }
                }
            }

            &.mobile {
                .button-group {
                    position: sticky;
                    left: 0;
                    bottom: 0;
                    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

                    button {
                        border-radius: 0;
                    }
                }
            }
        }
    }
}