.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    overflow: hidden;

    &.mobile {
        .footer-content {
            padding-top: 140px;

            .footer-block {
                &.cta {
                    align-items: flex-start;

                    .btn {
                        margin-left: 0 !important;
                    }
                }
            }
        }
    }

    .footer-background-mask {
        --sectionBackgroundOverflow: visible;
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: var(--sectionBackgroundOverflow);

        .footer-background {
            --angleNormal: -6deg;
            --sectionAngle: var(--angleNormal);
            --sectionAngleMaxHeight: none;
            --sectionTransformOrigin: 100% 0;
            --backgroundColor: #f6f9fc;
            position: relative;
            height: 100%;
            max-height: var(--sectionAngleMaxHeight);
            width: 100%;
            top: 0;
            left: 0;
            transform-origin: var(--sectionTransformOrigin);
            transform: skewY(var(--sectionAngle));
            background: var(--backgroundColor);
            overflow: hidden;
        }
    }

    .footer-content {
        z-index: 1;
        width: calc(var(--layoutWidth) + 40px);
        border: dashed 1px rgba(66, 71, 112, 0.09);
        border-bottom: none;
        border-top: none;
        padding: 260px 20px 200px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 20px;

        .footer-block {
            flex: 1;
            padding: 5px 20px;
            border-left: dashed 1px rgba(66, 71, 112, 0.09);
            display: flex;
            flex-direction: column;

            &.align-bottom {
                justify-content: flex-end;
            }
            
            
            &:first-child,
            &:last-child {
                border: none;
            }
    
            img {
                width: 22px;
                margin-bottom: 20px;
            }
    
            .copyright {
                font-size: 0.9rem;
                color: #425466;
                line-height: 1.5;
    
                a {
                    color: #9801cc;
                    text-decoration: none;
                }
            }
        }
    
        .footer-navigation {
            flex: 1;
    
            ul {
                list-style: none;
                list-style-type: none;
                padding: 0;
                margin: 0;
    
                li {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
    
                    a {
                        color: #425466;
                        text-decoration: none;
                        font-weight: 400;
                        font-size: 1em;
                        padding: 0;
    
                        &:hover {
                            color: #9801cc;
                            background-color: transparent;
                        }
                    }
                }
            }
    
            .footer-naviation-header {
                font-size: 1rem;
                font-weight: bold;
                color: #333;
                margin-bottom: 10px;
            }
        }
    
        .cta {
            flex: 1;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}