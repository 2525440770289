.wrapper {
    display: flex;
    gap: var(--gap);
    margin: auto;
    max-width: 100%;

    --size: clamp(10rem, 1rem + 40vmin, 10rem);
    --gap: calc(var(--size) / 14);
    --duration: 60s;
    --scroll-start: 0;
    --scroll-end: calc(-100% - var(--gap));

    &.mobile {
        .marquee {
            min-width: 100vw;
            max-width: 100vw;
        }
    }
}

.marquee {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    mask-image: linear-gradient(var(--mask-direction, to right),
            hsl(0 0% 0% / 0),
            hsl(0 0% 0% / 1) 20%,
            hsl(0 0% 0% / 1) 80%,
            hsl(0 0% 0% / 0));
}

.marquee__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll-x var(--duration) linear infinite;

    @media (prefers-reduced-motion: reduce) {
        animation-play-state: paused;
    }

    @keyframes scroll-x {
        from {
            transform: translateX(var(--scroll-start));
        }

        to {
            transform: translateX(var(--scroll-end));
        }
    }
}

.marquee img {
    display: grid;
    place-items: center;
    padding: calc(var(--size) / 10);
    border-radius: 0.5rem;
}