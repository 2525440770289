.testimonials {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    overflow: hidden;

    &.mobile {
        .testimonials-content {
            padding-top: 140px;

            .testimonials-description-container {
                flex-direction: column;
            }
        }
    }

    .testimonials-background-mask {
        --sectionBackgroundOverflow: visible;
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: var(--sectionBackgroundOverflow);

        .testimonials-background {
            --angleNormal: -6deg;
            --sectionAngle: var(--angleNormal);
            --sectionAngleMaxHeight: none;
            --sectionTransformOrigin: 100% 0;
            --backgroundColor: #0a2540;
            position: relative;
            height: 100%;
            max-height: var(--sectionAngleMaxHeight);
            width: 100%;
            top: 0;
            left: 0;
            transform-origin: var(--sectionTransformOrigin);
            transform: skewY(var(--sectionAngle));
            background: var(--backgroundColor);
            overflow: hidden;
        }
    }

    .testimonials-content {
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: calc(var(--layoutWidth) + 40px);
        border: dashed 1px rgba(66, 71, 112, 0.09);
        border-bottom: none;
        border-top: none;
        padding: 260px 20px 0;

        .headline {
            font-size: 1.4em;
            font-weight: 700;
            color: #9801cc;
            margin-bottom: 30px;
        }

        .testimonials-description-container {
            display: flex;
            align-items: flex-start;
            padding-bottom: 80px;

            .left {
                display: flex;
                flex: 1;
                flex-direction: column;

                .testimonials-section {
                    display: flex;
                    flex-direction: column;
            
                    .testimonials-section-headline {
                        font-size: 3em;
                        font-weight: 700;
                        color: #fff;
                        margin-bottom: 20px;
                        letter-spacing: -0.2rem;
                    }
            
                    .testimonials-section-description {
                        display: flex;
                        flex-direction: column;
                        line-height: 28px;
                        color: #adbdcc;
                    }
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                flex: 1;
                position: relative;
                padding-left: 80px;

                &.mobile {
                    width: 100%;
                    padding-left: 0;

                    .carousel-container {
                        position: relative;
                        top: auto;

                        .carousel-navigation {
                            margin-left: 0;
                            margin-top: 40px;
                        }

                        .testimonials-animation-container {
                            height: 320px;

                            .testimonial-card {
                                position: relative;
                                top: 0;
                                left: 0;

                                &:not(.active) {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .carousel-container {
                    position: absolute;
                    top: -150px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;

                    .carousel-navigation {
                        display: flex;
                        justify-content: center;
                        margin-top: 10px;
                        margin-left: 80px;
                        margin-bottom: 40px;
                        width: 100%;
                
                        .carousel-dot {
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: rgb(120, 120, 120);
                            margin: 0 5px;
                            cursor: pointer;
                            transition: background-color 0.3s ease-in-out;
                
                            &.active {
                                background-color: white;
                            }
                        }
                    }

                    .testimonials-animation-container {
                        z-index: 1;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: 100%;

                        .testimonial-card {
                            opacity: 0;
                            transition: opacity 0.5s ease-in-out;
                            animation: fadeOut 0.1s ease-in-out forwards;
                            position: absolute;
                            top: 0;
                            left: 80px;

                            background-color: #f9f9f9;
                            border-radius: 8px;
                            border-left: 4px solid #9801cc;
                            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                            padding: 20px;

                            @keyframes fadeOut {
                                0% {
                                    opacity: 1;
                                }

                                100% {
                                    opacity: 0;
                                }
                            }

                            &.active {
                                opacity: 1;
                                animation: slideInRight 0.5s ease-in-out forwards;

                                @keyframes slideInRight {
                                    0% {
                                        transform: translateX(100%);
                                        opacity: 0;
                                    }

                                    100% {
                                        transform: translateX(0);
                                        opacity: 1;
                                    }
                                }
                            }

                            blockquote {
                                margin: 0;
                                position: relative;
                                font-size: 0.9rem;
                                font-weight: 400;
                                line-height: 1.4;
                                padding: 0 0 0 40px;
                            }
                        }

                        .reviewer {
                            display: flex;
                            flex-direction: column;

                            .name-container {
                                display: flex;
                                align-items: center;

                                img {
                                    width: 32px;
                                }

                                .name {
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                    margin-left: 8px;
                                }
                            }

                            .stars {
                                display: flex;
                                align-items: center;
                                margin-left: 40px;
                                margin-bottom: 8px;
                                margin-top: 4px;

                                .star {
                                    color: #FFD700;
                                    font-size: .9rem;
                                    margin-right: 3px;

                                    &.filled {
                                        color: #F39C12;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}