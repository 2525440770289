@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i&display=swap');

:root {
    // --transformOriginX: calc(var(--gutterWidth) * 0.8);
    --scrollbarWidth: 0px;
    --windowWidth: calc(100vw - var(--scrollbarWidth));
    --layoutWidthMax: 1080px;
    --layoutWidth: var(--layoutWidthMax);
    --gradientColors: #9801cc, #13b2f9;
    --sectionPaddingTop: calc(var(--sectionPaddingTopMax)*1px);
    --sectionPaddingTopMax: 116;
    --angleStrong: -12deg;
    --angleStrongSin: 0.212;
    --gutterWidth: calc(var(--windowWidth)/2 - var(--layoutWidth)/2);
    --columnPaddingNormal: 16px;
    --globalFontColor: #425466;
    --gradientColorZero: #9801cc;
    // --gradientColorOne: #ff333d;
    --gradientColorOne: #4f46e5;
    --gradientColorTwo: #90e0ff;
    // --gradientColorThree: #ffcb57;
    --gradientColorThree: #576fff;
    --gradientColorZeroTransparent: rgba(169,96,238,0);
    --gradientColorOneTransparent: rgba(255,51,61,0);
    --gradientColorTwoTransparent: rgba(144,224,255,0);
    --gradientColorThreeTransparent: rgba(255,203,87,0);

    @media (max-width: 767px) {
        --layoutWidthMax: calc(100vw - 40px);
    }
}

html {
    -webkit-font-smoothing: antialiased;
}

html,
body {
    height: auto;
    margin: 0;
    padding: 0;
    width: 100%;
    color: var(--globalFontColor);
    overflow-x: hidden;
    font-family: Ubuntu, Helvetica, sans-serif;
    background-color: #e7ecf1;
}

#root {
    height: 100%;
}

* {
    box-sizing: border-box;
}

a {
    color: #9801cc;
}

.no-display {
    display: none;
}

input,
.special-label,
.react-tel-input {
    font-family: Ubuntu, Helvetica, sans-serif !important;
}

.btn {
    padding: 10px 20px;
    background-color: white;
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    margin-left: 10px;
    box-shadow: 0 5px 7px -3px rgba(0, 0, 0, 0.3);
    text-decoration: none;
    transition: all 200ms ease;
    width: fit-content;

    @media (hover:hover) {
        &:hover {
            box-shadow: 0 6px 11px -4px rgba(0, 0, 0, 0.3);
            transform: translate(0px, -1.5px);
        }
    }

    &.transparent {
        background-color: transparent;
        box-shadow: none;

        @media (hover:hover) {
            &:hover {
                background-color: transparent;
                text-decoration: underline;
                box-shadow: none;
                transform: none;
            }
        }
    }

    &.purple-transparent {
        background-color: transparent !important;
        border: solid 1px #9801cc !important;
        color: #9801cc !important;
    }

    &.black-transparent {
        background-color: transparent !important;
        border: solid 1px #000 !important;
        color: #000 !important;
    }

    &.white {
        color: white;
    }

    &.accent {
        color: #000;
    }

    &.arrow {
        background-color: #000;
        color: white;

        svg {
            margin-left: 5px;
        }
    }
}

.recaptcha-privacy-terms {
    margin-top: 20px;
    font-size: .6em;
    padding: 0 2px;
}