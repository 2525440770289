.section-divider {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
    margin: 20px 0;

    .section-divider-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #ccc;
    }

    .section-name,
    .right {
        position: absolute;
        background-color: #f6f6f6;
        padding: 0 8px;
        
    }

    .section-name {
        position: absolute;
        left: 10px;
        top: -8px;
        font-size: .8em;
        font-weight: 700;
    }

    .right {
        right: 0;
        top: -12px;
        text-align: right;
        padding-right: 0;
    }
}