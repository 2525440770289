.value {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    overflow: hidden;

    &.mobile {
        .value-content {
            padding-top: 140px;

            .value-description-container {
                padding-bottom: 40px;
            }

            .metrics-container {
                margin-left: 0;
                align-items: flex-start;

                .metric {
                    .metric-value {
                        font-size: 1em;
                    }

                    .metric-description {
                        font-size: .8em;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    .value-background-mask {
        --sectionBackgroundOverflow: visible;
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: var(--sectionBackgroundOverflow);

        .value-background {
            --angleNormal: -6deg;
            --sectionAngle: var(--angleNormal);
            --sectionAngleMaxHeight: none;
            --sectionTransformOrigin: 100% 0;
            --backgroundColor: #0a2540;
            position: relative;
            height: 100%;
            max-height: var(--sectionAngleMaxHeight);
            width: 100%;
            top: 0;
            left: 0;
            transform-origin: var(--sectionTransformOrigin);
            transform: skewY(var(--sectionAngle));
            background: var(--backgroundColor);
            overflow: hidden;
        }
    }

    .value-content {
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: calc(var(--layoutWidth) + 40px);
        border: dashed 1px rgba(66, 71, 112, 0.09);
        border-bottom: none;
        border-top: none;
        padding: 260px 20px 0;

        .headline {
            font-size: 1.4em;
            font-weight: 700;
            color: #9801cc;
            margin-bottom: 30px;
        }

        .value-description-container {
            display: flex;
            align-items: flex-start;
            padding-bottom: 80px;

            .left {
                display: flex;
                flex: 1;
                flex-direction: column;

                .value-section {
                    display: flex;
                    flex-direction: column;
            
                    .value-section-headline {
                        font-size: 3em;
                        font-weight: 700;
                        color: #fff;
                        margin-bottom: 20px;
                        letter-spacing: -0.2rem;
                    }
            
                    .value-section-description {
                        display: flex;
                        flex-direction: column;
                        line-height: 28px;
                        color: #adbdcc;
                    }
                }
            }

            .right {
                display: flex;
                justify-content: flex-end;
                flex: 1;
                position: relative;

                .value-animation-container {
                    --gutter: 14px;
                    position: absolute;
                    top: -80px;
                    left: 60px;
                    display: grid;
                    padding: var(--gutter);
                    border-radius: 8px;
                    background: linear-gradient(hsla(0, 0%, 100%, .4), hsla(0, 0%, 100%, .3) 25%, rgba(246, 249, 252, .3) 50%, #0a2540 60%);
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    user-select: none;
                    height: 288px;
                    z-index: 1;

                    img {
                        height: 288px;
                        object-fit: cover;
                        border-radius: 8px;
                        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
                    }
                }
            }
        }

        .metrics-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #0a2540;
            padding-bottom: 80px;
            margin-left: -20px;

            .metric {
                display: flex;
                flex-direction: column;

                .metric-value {
                    font-size: 1.5em;
                    font-weight: 700;
                    color: white;
                    margin-bottom: 18px;
                    border-left: solid 1px #9801cc;
                    padding-left: 20px;
                    text-align: left;
                }

                .metric-description {
                    color: #adbdcc;
                    line-height: 20px;
                    font-size: .9em;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
    }
}