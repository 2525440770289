.pricing {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    overflow: hidden;

    &.mobile {
        .pricing-content {
            padding-top: 80px;

            .pricing-description-container {
                flex-direction: column;

                .left {
                    .pricing-section {
                        .pricing-section-description {
                            .pricing-section-extras {
                                text-align: center;
                            }
                        }
                    }
                }

                .right {
                    padding-left: 0;
                    padding-top: 40px;

                    .pricing-card-container {
                        position: relative;
                        top: auto;
                        left: auto;

                        .pricing-card {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .pricing-content {
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: calc(var(--layoutWidth) + 40px);
        border: dashed 1px rgba(66, 71, 112, 0.09);
        border-bottom: none;
        border-top: none;
        padding: 140px 20px 0;

        .headline {
            font-size: 1.4em;
            font-weight: 700;
            color: #9801cc;
            margin-bottom: 30px;
        }

        .pricing-description-container {
            display: flex;
            align-items: flex-start;
            padding-bottom: 80px;

            .left {
                display: flex;
                flex: 1;
                flex-direction: column;

                .pricing-section {
                    display: flex;
                    flex-direction: column;

                    .pricing-section-headline {
                        font-size: 3em;
                        font-weight: 700;
                        color: #000;
                        margin-bottom: 20px;
                        letter-spacing: -0.2rem;
                    }

                    .pricing-section-description {
                        display: flex;
                        flex-direction: column;
                        line-height: 28px;

                        .pricing-section-extras {
                            display: block;
                            margin-top: 20px;
                    
                            .btn {
                                margin-left: 0 !important;
                            }
                        }
                    }
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                flex: 1;
                position: relative;
                padding-left: 80px;

                .pricing-card-container {
                    position: absolute;
                    top: -60px;
                    left: 180px;
                    display: flex;
                    
                    .pricing-card {
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        /* Distribute items equally */
                        height: 100%;
                        /* Make the container take full height */
                        width: 380px;

                        background-color: #f9f9f9;
                        border-radius: 8px;
                        border: none;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    }

                    .pricing-meta-top,
                    .pricing-meta-bottom {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;

                        .pricing-meta-large {
                            font-size: 3em;
                            font-weight: 700;
                            color: #000;
                            letter-spacing: -0.2rem;
                            position: relative;

                            .meta-asterik {
                                position: absolute;
                                top: 5px;
                                right: 5px;
                                font-size: .3em;
                            }
                        }

                        .pricing-meta-medium {
                            font-size: 1.2rem;
                            color: #666;

                            .link {
                                margin: 0 0 0 5px;
                                cursor: pointer;
                                color: #9801cc;
                            }
                        }

                        .pricing-meta-small {
                            font-size: .6em;
                            color: #adbdcc;
                        }

                        .pricing-meta-large,
                        .pricing-meta-medium,
                        .pricing-meta-small {
                            padding: 0 20px;
                            margin: 20px 0;
                            display: flex;
                        }
                    }

                    .pricing-meta-top {
                        padding: 20px 0;
                    }

                    .pricing-meta-bottom {
                        background-color: #9801cc;
                        border-bottom-right-radius: 8px;
                        border-bottom-left-radius: 8px;

                        .pricing-meta-large {
                            color: #fff;
                            margin: 0;
                        }

                        .pricing-meta-medium {
                            color: #f9f9f9;
                            margin-top: 40px;
                        }
                    }
                }
            }
        }
    }
}